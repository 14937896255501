import { FullUserFlat } from 'redux/services/identity-service/types';

export type DescriptionDataType = {
  labelHeading: string;
  labelDescription: string;
};

export const isStore = (pathname: string): boolean => {
  const paths = pathname.split('/');
  if (paths.length > 3 && paths[3] === 'store' && paths[4].startsWith('S')) {
    return true;
  }
  return false;
};

export const isCDKHome = (pathname: string): boolean => {
  const paths = pathname.split('/');
  if (paths.length > 1 && paths[1] === 'cdk_home') {
    return true;
  }
  return false;
};

export const isEnterprise = (pathname: string): boolean => {
  const paths = pathname.split('/');
  if (
    paths.length === 3 &&
    paths[1] === 'enterprise' &&
    paths[2].startsWith('E')
  ) {
    return true;
  }
  return false;
};

export const isUserDetails = (pathname: string): boolean => {
  const paths = pathname.split('/');
  if (paths.length > 2 && paths[1] === 'user_details' && paths[2] === 'edit') {
    return true;
  }
  return false;
};

export const getAvatarTextFromEnterprise = (fullUser: FullUserFlat): string => {
  const enterpriseName = fullUser?.enterprise?.name;
  if (enterpriseName) {
    return enterpriseName[0];
  }
  return '';
};
