const apiEndPoints = {
  getStoreAdminDetails: (storeId: string): string =>
    `/stores/${storeId}/enterpriseAdmin`,
  getUserImportJobs: '/1.1.1/rest/dms/users/schedule',
  getAllRoles: (enterpriseId: string): string =>
    `/enterprises/${enterpriseId}/roles`,
  postBulkAssignStores: `/storesassignment?action=create`,
  postBulkAssignRoles: `/rolesassignment?action=create`,
  postBulkUnAssignStores: `/storesassignment?action=delete`,
  postBulkUnAssignRoles: `/rolesassignment?action=delete`,
  postUserassignmentByRole: `/rolesassignment`,
  getUserRoles: (userid: string): string => `/users/${userid}/roles`,
  getUserStores: (userid: string): string => `/stores/search?userId=${userid}`,
  getAllRoleTemplates: (enterpriseId: string): string =>
    `/roleTemplates?enterpriseId=${enterpriseId}`,
  getEnterprisePermissions: (enterpriseId: string): string =>
    `/enterprises/${enterpriseId}/permissions`,
  updateRoles: (enterpriseId: string, roleGuid: string): string =>
    `/enterprises/${enterpriseId}/roles/${roleGuid}`,
  getStoreAdminDetailsWithPermissions: (loginId: string): string =>
    `/${loginId}/primaryStore/enterpriseAdmin`,
  postRoleAssign: '/rolesassignment?action=create',
  postStoreAssign: '/storesassignment?action=create',
  postRoleUnassign: '/rolesassignment?action=delete',
  postStoreUnassign: '/storesassignment?action=delete',
  putDefaultStore: (loginId: string): string =>
    `/users/${loginId}?updateFields=defaultstore`,
  getStoreLevelRoles: (
    enterpriseId: string,
    userId: string,
    simpleId: string
  ): string =>
    `stores/roles?enterpriseId=${enterpriseId}&userId=${userId}&simpleId=${simpleId}`
};

export default apiEndPoints;
