/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call, 
 @typescript-eslint/no-unsafe-return */
import { TOAST_VARIANTS, Toast } from 'cdk-radial';
import { Loading } from 'components/organisms/common';
import ErrorToast from 'components/ui/ErrorToast';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useGetRolesQuery } from 'redux/services/authz-service';
import { useGetAllStoresQuery } from 'redux/services/eis-core-service';
import { StoresDetails } from 'redux/services/types/eis-core-service-types';
import {
  FileDataType,
  setTemplateUploadData
} from 'redux/slices/csvImportSlice';
import { RootState } from 'redux/store';
import { useClient } from 'providers/ClientProvider';
import ErrorTable from '../../../molecules/csvUserImport/StepTwo/ErrorTable/ErrorTable';
import TemplateUpload from '../../../molecules/csvUserImport/StepTwo/TemplateUpload/TemplateUpload';
import StyledStepTwo from './styled';
import { messages } from '../messages';

export const getTemplateLabel = (templateType: string): string => {
  switch (templateType) {
    case 'dms':
      return 'DMS';
    case 'nondms':
      return 'Non - DMS';
    default:
      return '';
  }
};

const StepTwo = (): JSX.Element => {
  const intl = useIntl();

  const client = useClient();
  const { userInfo, enterpriseId } = client;

  const { stores, isLoading: isStoresLoading } = useGetAllStoresQuery(
    {
      enterpriseId: enterpriseId || '',
      includeDepartments: false
    },
    {
      skip: !userInfo?.fullUser,
      selectFromResult: ({ data, ...rest }) => ({
        stores: data?.stores.reduce(
          (acc: { [key: string]: StoresDetails }, curr: StoresDetails) => ({
            ...acc,
            [curr.id]: curr
          }),
          {}
        ),
        ...rest
      })
    }
  );

  const {
    roles,
    isLoading: isRolesLoading,
    isError: isRolesError,
    error: rolesError
  } = useGetRolesQuery(enterpriseId || '', {
    skip: !userInfo?.fullUser,
    selectFromResult: ({ data, ...rest }) => ({
      roles: data?.reduce((acc, curr) => ({ ...acc, [curr.code]: curr }), {}),
      ...rest
    })
  });

  const dispatch = useDispatch();

  const { templateType, fileDetails } = useSelector(
    (state: RootState) => state.slices.csvImportState
  );

  const setFileDetails = (fileData: FileDataType) => {
    dispatch(setTemplateUploadData(fileData));
  };

  const errors = fileDetails?.errorContent?.mainErrors;
  let csvErrorTxt = '';
  Array.isArray(errors) &&
    errors.forEach((rule: string) => {
      if (
        rule === 'Please correct the following error and re-upload the CSV file'
      ) {
        csvErrorTxt = intl.formatMessage(messages.csvTemplateError);
      } else if (rule === 'Please select a valid template') {
        csvErrorTxt = intl.formatMessage(messages.templateError);
      } else {
        csvErrorTxt = rule;
      }
    });

  return (
    <>
      {isRolesError && <ErrorToast dataError={rolesError} />}

      {userInfo?.fullUser && (
        <StyledStepTwo>
          <h4 className="main-heading">
            {intl.formatMessage(messages.uploadTemplate)}(
            {getTemplateLabel(templateType)})
          </h4>
          <p className="description">
            {intl.formatMessage(messages.uploadDescription)}
          </p>

          {isRolesLoading || isStoresLoading ? (
            <Loading />
          ) : (
            <TemplateUpload
              fileDetails={fileDetails}
              setFileDetails={setFileDetails}
              uid={templateType}
              allStores={stores || {}}
              allRoles={roles || {}}
            />
          )}

          {fileDetails &&
            fileDetails.errorContent &&
            fileDetails.errorContent.mainErrors && (
              <Toast
                className="custom-toast"
                variant={TOAST_VARIANTS.NEGATIVE}
                content={<p className="error-p">{csvErrorTxt}</p>}
                id="toast-1"
              />
            )}
          {fileDetails && fileDetails.rows && (
            <Toast
              className="custom-toast"
              variant={TOAST_VARIANTS.POSITIVE}
              content={
                <p className="error-p">
                  {intl.formatMessage(messages.validationContinue)}
                </p>
              }
              id="toast-1"
            />
          )}
          {fileDetails &&
            fileDetails.errorContent &&
            fileDetails.errorContent.content && (
              <ErrorTable
                rows={fileDetails.errorContent.content}
                uid={templateType}
              />
            )}
        </StyledStepTwo>
      )}
    </>
  );
};

export default StepTwo;
