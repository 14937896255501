import styled from 'styled-components';
import { ShellContainer } from 'cdk-radial';
import { Z_INDEX } from 'constants/zindex';

const StyledCommonAppLayout = styled.div`
  .main-section {
    display: flex;
    max-height: calc(100vh - 70px);
    overflow-y: auto;
  }

  .main-section-impersonation {
    max-height: calc(100vh - 70px);
    border: 6px solid #30d158;
    margin: 14px;
    padding: 7px;
  }

  .card-div {
    background-color: #ffffff;
    border: 1px;
    max-height: calc(100vh - 74px);
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    overflow-y: auto;
    min-height: 80vh !important;
  }

  .show-table-border {
    border: 1px solid #b9bbbe;
    border-radius: 0.5rem;
    overflow: hidden;
    height: 100%;
  }
  .show-table-height {
    height: 80% !important;
  }

  .ag-center-cols-container {
    width: 100%;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
`;

const StyledShellContainer = styled(ShellContainer)`
  grid-template-rows: auto auto 1fr auto;
  .global-toast {
    z-index: ${Z_INDEX.MAX_INDEX};
  }
`;

export { StyledCommonAppLayout, StyledShellContainer };
