import { Z_INDEX } from 'constants/zindex';
import styled from 'styled-components';

const StyledAutoMaprolesDropDown = styled.div`
  .auromap-roles-dropdown {
    z-index: ${Z_INDEX.ROLES_DROPDOWN};
  }
`;

export default StyledAutoMaprolesDropDown;
