import { Menu, MenuListItem } from 'cdk-radial';
import { Z_INDEX } from 'constants/zindex';
import styled from 'styled-components';

export const StyledSingleSelectFilter = styled.div``;

export const StyledMenu = styled(Menu)`
  pointer-events: all !important;
  z-index: ${Z_INDEX.TABLE_PAGINATION_INDEX};
`;

export const StyledMenuListItem = styled(MenuListItem)`
  z-index: ${Z_INDEX.TABLE_PAGINATION_INDEX};
`;
