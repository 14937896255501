import { Z_INDEX } from 'constants/zindex';
import styled from 'styled-components';

const StyledAppSwitcher = styled.div`
    display: flex;
    align-items: center;
    justify-content: center
    z-index: ${Z_INDEX.TAB_INDEX};
  
    .global-navigation-app-switcher-button{
      background-color:grey
    }
  .global-navigation-app-switcher-button:hover{
      background-color:grey
    }
`;

export default StyledAppSwitcher;
