/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { createApi } from '@reduxjs/toolkit/query/react';
import { map, flatten, union } from 'lodash';
import baseQuery from './baseQuery';
import apiEndPoints from './apiEndPoints';
import {
  PostUserRolesPayload,
  PostUserStoresPayload,
  GetPermisssionPayloadType,
  UserPermissionsType,
  TransformedUserPermissions,
  UserImpersonationStatusResponseType,
  InvokeImpersonationPayload,
  AppRecord,
  UserAppsResponse,
  AllAppsResponse,
  GetUserCVRStatus,
  ProductsType,
  RolesResponseType,
  UpdateCDKEditAccessBody,
  GetStoresUserCountType,
  EnterpriseCDKAccessDetailsType
} from './types';

const legacyBasePath: string = process.env.REACT_APP_BASE_URL || '';

export const authzServiceApi = createApi({
  reducerPath: 'authz',
  baseQuery,
  tagTypes: [
    'getUserDetails',
    'getPermissions',
    'getUserImpersonationStatus',
    'getEnterpriseCDKAccessDetails'
  ],
  endpoints: builder => ({
    getPermissions: builder.query<
      TransformedUserPermissions,
      GetPermisssionPayloadType
    >({
      query: body => ({
        url: `${apiEndPoints.getUserPermissions}`,
        method: 'POST',
        body
      }),
      transformResponse: (response: UserPermissionsType) => {
        const pluckedPermissions = map(response, 'permission');
        const pluckedCode = map(
          map(flatten(map(response, 'rules')), 'resource'),
          'code'
        );
        const allPermissionsAssigedToUser = union(
          pluckedPermissions,
          pluckedCode
        );
        const permissionsData = response.reduce(
          (acc, curr) => ({ ...acc, [curr.permission]: curr }),
          {}
        );
        return { ...permissionsData, allPermissionsAssigedToUser };
      },
      providesTags: ['getPermissions']
    }),
    getUserApps: builder.query<
      { userApps: AppRecord[] },
      Record<string, string>
    >({
      queryFn: async (
        { userGUID, enterpriseId },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const getUserApps = fetchWithBQ(
          `${apiEndPoints.getUserApps}?userGUID=${userGUID}&enterpriseId=${enterpriseId}`
        );
        const getAllApps = fetchWithBQ(
          `${legacyBasePath}/${apiEndPoints.getAllApps}`
        );
        const rawResponse = await Promise.allSettled([getUserApps, getAllApps]);
        const response = rawResponse.filter(
          res => res.status === 'fulfilled'
        ) as [
          PromiseFulfilledResult<UserAppsResponse>,
          PromiseFulfilledResult<AllAppsResponse>
        ];
        const userApps: AppRecord[] = [];

        if (response[0]?.value?.data && response[1]?.value?.data) {
          const allApps: { [key: string]: AppRecord } = {};
          Object.entries(response[1]?.value?.data).forEach(([key, value]) => {
            const upperCaseKey = key.toUpperCase();
            allApps[upperCaseKey] = value;
          });
          const myAppCodes = response[0].value.data?.map(app =>
            app.code.toUpperCase()
          );
          myAppCodes.forEach(appKey => {
            if (appKey && allApps && allApps[appKey]) {
              userApps.push(allApps[appKey]);
            }
          });
        }
        return {
          data: {
            userApps
          }
        };
      }
    }),

    postUserStatusForCVR: builder.mutation<void, GetUserCVRStatus>({
      query: body => ({
        url: `${apiEndPoints.postUserStatusForCVR}`,
        method: 'POST',
        body
      })
    }),
    getUserImpersonationStatus: builder.query<
      UserImpersonationStatusResponseType,
      string
    >({
      query: (userGuid: string) =>
        `${apiEndPoints.getUserImpersonationStatus}/${userGuid}/impersonate`,
      providesTags: ['getUserImpersonationStatus']
    }),
    postInvokeImpersonation: builder.mutation<void, InvokeImpersonationPayload>(
      {
        query: ({ userGuid, body }) => ({
          url: `${apiEndPoints.getUserImpersonationStatus}/${userGuid}/impersonate`,
          method: 'POST',
          body
        }),
        invalidatesTags: ['getUserDetails', 'getUserImpersonationStatus']
      }
    ),
    revokeImpersonation: builder.mutation<void, string>({
      query: (userGuid, body = {}) => ({
        url: `${apiEndPoints.getUserImpersonationStatus}/${userGuid}/impersonate`,
        method: 'DELETE',
        body
      }),
      invalidatesTags: ['getUserDetails', 'getUserImpersonationStatus']
    }),
    postNewUserRoles: builder.mutation<void, PostUserRolesPayload>({
      query: body => ({
        url: `${apiEndPoints.postAssignUserRoles}`,
        method: 'POST',
        body
      })
    }),
    postNewUserStores: builder.mutation<void, PostUserStoresPayload>({
      query: body => ({
        url: `${apiEndPoints.postAssignUserStores}`,
        method: 'POST',
        body
      })
    }),
    deleteRole: builder.mutation<void, string>({
      query: (roleGuid: string) => ({
        url: `${apiEndPoints.deleteRole(roleGuid)}`,
        method: 'DELETE'
      })
    }),
    getServiceUsersPermissions: builder.query<ProductsType[], void>({
      query: () => `${apiEndPoints.getServiceUserPermissions}`
    }),

    getServiceUsersPermissionsByRoleGuid: builder.query<ProductsType[], string>(
      {
        query: (roleGuid: string) =>
          `${apiEndPoints.getServiceUserPermissionsByRoleId(roleGuid)}`
      }
    ),
    getRoles: builder.query<RolesResponseType[], string>({
      query: (enterpriseId: string) =>
        `${apiEndPoints.getRoles}?enterpriseId=${enterpriseId}`
    }),
    getUserStoresCount: builder.query<GetStoresUserCountType, string>({
      query: (enterpriseId: string) =>
        `${apiEndPoints.getUserStoresDetails}/${enterpriseId}/storesUsers/count`
    }),

    PostUpdateCDKEditAccess: builder.mutation<void, UpdateCDKEditAccessBody>({
      query: ({ enterpriseId, body }) => ({
        url: `${apiEndPoints.cdkEditAccess(enterpriseId)}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['getEnterpriseCDKAccessDetails']
    }),
    getEnterpriseCDKAccessDetails: builder.query<
      EnterpriseCDKAccessDetailsType,
      string
    >({
      query: (enterpriseId: string) =>
        `${apiEndPoints.cdkEditAccess(enterpriseId)}`,
      providesTags: ['getEnterpriseCDKAccessDetails']
    }),
    putUserOrgunitRoles: builder.mutation<void, any>({
      query: body => ({
        url: `${apiEndPoints.putUserOrgunitRoles}`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useGetPermissionsQuery,
  useGetUserAppsQuery,
  usePostUserStatusForCVRMutation,
  useGetUserImpersonationStatusQuery,
  usePostInvokeImpersonationMutation,
  useRevokeImpersonationMutation,
  usePostNewUserRolesMutation,
  usePostNewUserStoresMutation,
  useDeleteRoleMutation,
  useGetServiceUsersPermissionsQuery,
  useGetServiceUsersPermissionsByRoleGuidQuery,
  usePostUpdateCDKEditAccessMutation,
  useGetEnterpriseCDKAccessDetailsQuery,
  useGetUserStoresCountQuery,
  useGetRolesQuery,
  usePutUserOrgunitRolesMutation
} = authzServiceApi;
