type RouteIdentifiers = {
  [key: string]: {
    id: string;
    pageId: string;
    path: string;
    main?: boolean;
    contentHeading: string;
    pathId: string;
  };
};

const routeIdentifiers: RouteIdentifiers = {
  CDK_HOME: {
    id: 'CDK_HOME',
    path: '/cdk_home',
    pageId: 'CDK_HOME',
    main: true,
    contentHeading: 'All Enterprises',
    pathId: 'cdk_home'
  },
  CDK_HOME_PAGE: {
    id: 'CDK_HOME_PAGE',
    path: '/',
    pageId: 'HOME',
    main: true,
    contentHeading: 'All Enterprises',
    pathId: '/'
  },
  HOME: {
    id: 'HOME',
    path: '/',
    pageId: 'HOME',
    main: true,
    contentHeading: 'Enterprise General',
    pathId: '/'
  },
  ENTERPRISE_GENERAL: {
    id: 'ENTERPRISE_GENERAL',
    path: '/enterprise/:enterpriseId',
    pageId: 'ENTERPRISE_GENERAL',
    main: true,
    contentHeading: 'Enterprise General',
    pathId: ''
  },
  MANAGE_USERS: {
    id: 'MANAGE_USERS',
    path: '/enterprise/:enterpriseId/manage_users',
    pageId: 'MANAGE_USERS',
    main: true,
    contentHeading: 'Manage Users',
    pathId: 'manage_users'
  },
  NEW_USER: {
    id: 'NEW_USER',
    path: '/enterprise/:enterpriseId/new_user',
    pageId: 'MANAGE_USERS',
    main: true,
    contentHeading: 'New User',
    pathId: 'new_user'
  },
  COPY_USER: {
    id: 'COPY_USER',
    path: '/enterprise/:enterpriseId/copy_user/:userId',
    pageId: 'COPY_USERS',
    main: true,
    contentHeading: 'Copy User',
    pathId: 'copy_user/:userId'
  },
  MERGE_USER: {
    id: 'MERGE_USER',
    path: '/enterprise/:enterpriseId/merge_user',
    pageId: 'MERGE_USER',
    main: true,
    contentHeading: 'Merge User',
    pathId: 'merge_user'
  },
  MANAGE_USER_IMPORTS: {
    id: 'MANAGE_USER_IMPORTS',
    path: '/enterprise/:enterpriseId/manage_user_imports',
    pageId: 'MANAGE_USERS_IMPORTS',
    main: true,
    contentHeading: 'Manage User Imports',
    pathId: 'manage_user_imports'
  },
  MANAGE_USER_IMPORTS_DETAILS: {
    id: 'MANAGE_USER_IMPORTS_DETAILS',
    path: '/enterprise/:enterpriseId/view_user_imports/:jobType/:jobId',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'Import Scheduled',
    pathId: 'view_user_imports/:jobType/:jobId'
  },
  NEW_USER_IMPORT: {
    id: 'NEW_USER_IMPORT',
    path: '/enterprise/:enterpriseId/new_user_import',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'Import From DMS',
    pathId: 'new_user_import'
  },
  NEW_USER_IMPORT_STEP_ONE: {
    id: 'NEW_USER_IMPORT_STEP_ONE',
    path: '/enterprise/:enterpriseId/new_user_import/step_1',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'Import From DMS',
    pathId: 'new_user_import/step_1'
  },
  NEW_USER_IMPORT_STEP_TWO: {
    id: 'NEW_USER_IMPORT_STEP_TWO',
    path: '/enterprise/:enterpriseId/new_user_import/step_2',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'New Import List',
    pathId: 'new_user_import/step_2'
  },
  NEW_USER_IMPORT_STEP_THREE: {
    id: 'NEW_USER_IMPORT_STEP_THREE',
    path: '/enterprise/:enterpriseId/new_user_import/step_3',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'Review Import List',
    pathId: 'new_user_import/step_3'
  },
  NEW_USER_IMPORT_STEP_FOUR: {
    id: 'NEW_USER_IMPORT_STEP_FOUR',
    path: '/enterprise/:enterpriseId/new_user_import/step_4',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'New Import List',
    pathId: 'new_user_import/step_4'
  },
  SCHEDULED_JOB_EDIT: {
    id: 'SCHEDULED_JOB_EDIT',
    path: '/enterprise/:enterpriseId/scheduled_job_edit',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'Scheduled Job Details',
    pathId: 'scheduled_job_edit'
  },
  SCHEDULED_JOB_EDIT_STEP_THREE: {
    id: 'SCHEDULED_JOB_EDIT_STEP_THREE',
    path: '/enterprise/:enterpriseId/scheduled_job_edit/step_3/:jobType/:jobId',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'Review Scheduled Job Details',
    pathId: 'scheduled_job_edit/step_3/:jobType/:jobId'
  },
  SCHEDULED_JOB_EDIT_STEP_FOUR: {
    id: 'SCHEDULED_JOB_EDIT_STEP_FOUR',
    path: '/enterprise/:enterpriseId/scheduled_job_edit/step_4/:jobType/:jobId',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'Scheduled Job Details',
    pathId: 'scheduled_job_edit/step_4/:jobType/:jobId'
  },
  CSV_USER_IMPORT: {
    id: 'CSV_USER_IMPORT',
    path: '/enterprise/:enterpriseId/csv_user_import',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'Import From CSV',
    pathId: 'csv_user_import'
  },
  CSV_USER_IMPORT_STEP_ONE: {
    id: 'CSV_USER_IMPORT_STEP_ONE',
    path: '/enterprise/:enterpriseId/csv_user_import/step_1',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'Import From CSV',
    pathId: 'csv_user_import/step_1'
  },
  CSV_USER_IMPORT_STEP_TWO: {
    id: 'CSV_USER_IMPORT_STEP_TWO',
    path: '/enterprise/:enterpriseId/csv_user_import/step_2',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'Import From CSV',
    pathId: 'csv_user_import/step_2'
  },
  CSV_USER_IMPORT_STEP_THREE: {
    id: 'CSV_USER_IMPORT_STEP_THREE',
    path: '/enterprise/:enterpriseId/csv_user_import/step_3',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'Import From CSV',
    pathId: 'csv_user_import/step_3'
  },
  CSV_USER_IMPORT_STEP_FOUR: {
    id: 'CSV_USER_IMPORT_STEP_FOUR',
    path: '/enterprise/:enterpriseId/csv_user_import/step_4',
    pageId: 'MANAGE_USERS_IMPORTS',
    contentHeading: 'Import From CSV',
    pathId: 'csv_user_import/step_4'
  },
  USER_DETAILS_EDIT: {
    id: 'USER_DETAILS_EDIT',
    path: '/enterprise/:enterpriseId/user_details/edit/:loginId',
    pageId: 'USER_DETAILS',
    contentHeading: 'User Details',
    pathId: 'user_details/edit/:loginId'
  },
  USERS: {
    id: 'USERS',
    path: '/enterprise/:enterpriseId/users',
    pageId: 'USERS',
    main: true,
    contentHeading: 'All Users',
    pathId: 'users'
  },
  EMAIL_NOTIFICATIONS: {
    id: 'EMAIL_NOTIFICATIONS',
    path: '/enterprise/:enterpriseId/email_notifications',
    pageId: 'EMAIL_NOTIFICATIONS',
    main: true,
    contentHeading: 'Email Notifications',
    pathId: 'email_notifications'
  },
  ROLES: {
    id: 'ROLES',
    path: '/enterprise/:enterpriseId/roles',
    pageId: 'ROLES',
    main: true,
    contentHeading: 'All Roles',
    pathId: 'roles'
  },
  CREATE_ROLE: {
    id: 'CREATE_ROLE',
    path: '/enterprise/:enterpriseId/create-role',
    pageId: 'CREATE_ROLE',
    contentHeading: 'New Role',
    pathId: 'create-role'
  },
  ROLE_DETAILS: {
    id: 'ROLE_DETAILS',
    path: '/enterprise/:enterpriseId/role/:roleGuid',
    pageId: 'ROLE_DETAILS',
    main: true,
    contentHeading: 'Role Details',
    pathId: 'role/:roleGuid'
  },
  ROLE_ASSIGNED_USERS: {
    id: 'ROLE_ASSIGNED_USERS',
    path: '/enterprise/:enterpriseId/role/:roleGuid/assigned-users',
    pageId: 'ROLE_ASSIGNED_USERS',
    main: true,
    contentHeading: 'Role Assigned Users',
    pathId: 'role/:roleGuid/assigned-users'
  },
  STORES: {
    id: 'STORES',
    path: '/enterprise/:enterpriseId/stores',
    pageId: 'STORES',
    main: true,
    contentHeading: 'All Stores',
    pathId: 'stores'
  },
  MANAGE_STORE_DETAILS: {
    id: 'MANAGE_STORE_DETAILS',
    path: '/enterprise/:enterpriseId/store/:storeId',
    pageId: 'STORE_DETAILS',
    contentHeading: 'Store Details',
    pathId: 'store/:storeId'
  },
  MANAGE_DEPARTMENT_DETAILS: {
    id: 'MANAGE_DEPARTMENT_DETAILS',
    path: '/enterprise/:enterpriseId/department/:departmentId',
    pageId: 'MANAGE_DEPARTMENT_DETAILS',
    contentHeading: 'Department Details',
    pathId: 'department/:departmentId'
  },
  SETTINGS_HOME: {
    id: 'SETTINGS_HOME',
    path: '/enterprise/:enterpriseId/settings',
    pageId: 'SETTINGS_HOME',
    main: true,
    contentHeading: 'Settings',
    pathId: 'settings'
  },
  MANAGE_STORE_MFA_SETTINGS: {
    id: 'MANAGE_STORE_MFA_SETTINGS',
    path: '/enterprise/:enterpriseId/settings/manage_store_mfa',
    pageId: 'SETTINGS_HOME',
    contentHeading: 'Manage Store MFA',
    pathId: 'settings/manage_store_mfa'
  },
  REPORTS_HOME: {
    id: 'REPORTS_HOME',
    path: '/enterprise/:enterpriseId/reports',
    pageId: 'REPORTS_HOME',
    main: true,
    contentHeading: 'Reports',
    pathId: 'reports'
  },
  USER_MANAGEMENT_REPORTS_HOME: {
    id: 'USER_MANAGEMENT_REPORTS_HOME',
    path: '/enterprise/:enterpriseId/user-management-reports',
    pageId: 'USER_MANAGEMENT_REPORTS_HOME',
    main: true,
    contentHeading: 'Logs and Reports',
    pathId: 'user-management-reports'
  },
  REPORTS_MFA: {
    id: 'REPORTS_MFA_ACTIVITY',
    path: '/enterprise/:enterpriseId/reports/mfa_activity',
    pageId: 'REPORTS_HOME',
    contentHeading: 'MFA Activity Logs',
    pathId: 'reports/mfa_activity'
  },
  REPORTS_MFA_ENTITY: {
    id: 'REPORTS_MFA_ACTIVITY',
    path: '/enterprise/:enterpriseId/reports/mfa_activity/:entityValue',
    pageId: 'REPORTS_HOME',
    contentHeading: 'MFA Entity Details',
    pathId: 'reports/mfa_activity/:entityValue'
  },
  MANAGE_ENTERPRISE_MFA_SETTINGS: {
    id: 'MANAGE_ENTERPRISE_MFA_SETTINGS',
    path: '/enterprise/:enterpriseId/settings/manage_enterprise_mfa',
    pageId: 'SETTINGS_HOME',
    contentHeading: 'Manage Enterprise MFA',
    pathId: 'settings/manage_enterprise_mfa'
  },
  MANAGE_DMS_CONFIGURATION: {
    id: 'MANAGE_DMS_CONFIGURATION',
    path: '/enterprise/:enterpriseId/settings/manage-dms-configuration',
    pageId: 'SETTINGS_HOME',
    contentHeading: 'Manage DMS Configuration',
    pathId: 'settings/manage-dms-configuration'
  },
  MANAGE_USER_MFA_SETTINGS: {
    id: 'MANAGE_USER_MFA_SETTINGS',
    path: '/enterprise/:enterpriseId/settings/manage_user_mfa',
    pageId: 'SETTINGS_HOME',
    contentHeading: 'Manage User MFA',
    pathId: 'settings/manage_user_mfa'
  },
  GROUPS: {
    id: 'GROUPS',
    path: '/enterprise/:enterpriseId/groups',
    pageId: 'GROUPSDETAILS',
    main: true,
    contentHeading: 'Groups',
    pathId: 'groups'
  },
  NEWGROUP: {
    id: 'NEWGROUP',
    path: '/enterprise/:enterpriseId/new_group',
    pageId: 'NEWGROUP',
    main: true,
    contentHeading: 'New Group',
    pathId: 'new_group'
  },
  VIEWGROUP: {
    id: 'VIEWGROUP',
    path: '/enterprise/:enterpriseId/view_group/:groupId',
    pageId: 'VIEWGROUP',
    main: true,
    contentHeading: 'View Group',
    pathId: 'view_group/:groupId'
  },
  MANAGE_CDK_EDIT_ACCESS_SETTINGS: {
    id: 'MANAGE_CDK_EDIT_ACCESS_SETTINGS',
    path: '/enterprise/:enterpriseId/settings/manage_cdk_edit_access',
    pageId: 'SETTINGS_HOME',
    contentHeading: 'CDK Edit Access',
    pathId: 'settings/manage_cdk_edit_access'
  },
  DMS_USER_STEPS: {
    id: 'DMS_USER_STEPS',
    path: '/enterprise/:enterpriseId/linkusers/:loginId',
    pageId: 'DMS_USER_STEPS',
    contentHeading: 'Link DMS Users',
    pathId: 'linkusers'
  },
  LEGACY_SETTINGS: {
    id: 'LEGACY_SETTINGS',
    path: '/enterprise/:enterpriseId/settings/legacy-settings',
    pageId: 'LEGACY_SETTINGS',
    main: true,
    contentHeading: 'Settings',
    pathId: 'settings/legacy-settings'
  },
  LEGACY_STORE_SETTINGS: {
    id: 'LEGACY_STORE_SETTINGS',
    path: '/enterprise/:enterpriseId/settings/:storeId/legacy-store-settings',
    pageId: 'LEGACY_STORE_SETTINGS',
    main: true,
    contentHeading: 'Store Settings',
    pathId: 'settings/:storeId/legacy-store-settings'
  },
  SERVICE_USERS: {
    id: 'SERVICE_USERS',
    path: '/service-users',
    pageId: 'SERVICE_USERS',
    main: true,
    contentHeading: 'Service Users',
    pathId: 'service-users'
  },
  SERVICE_USER_DETAILS_EDIT: {
    id: 'SERVICE_USER_DETAILS_EDIT',
    path: '/service-user/edit/:loginId',
    pageId: 'SERVICE_USER_DETAILS_EDIT',
    contentHeading: 'Service User Details',
    pathId: 'service-user/edit'
  },
  SERVICE_USER_NEW: {
    id: 'SERVICE_USER_NEW',
    path: '/service-user/new',
    pageId: 'SERVICE_USER_NEW',
    main: true,
    contentHeading: 'New Service User',
    pathId: 'service-user/new'
  },
  ALL_ENTERPRISE_USERS: {
    id: 'ALL_ENTERPRISE_USERS',
    path: '/all-enterprise-users',
    pageId: 'ALL_ENTERPRISE_USERS',
    main: true,
    contentHeading: 'Users',
    pathId: 'all-enterprise-users'
  },
  MOVE_STORES: {
    id: 'MOVE_STORES',
    path: '/move-stores',
    pageId: 'MOVE_STORES',
    main: true,
    contentHeading: 'Move Stores',
    pathId: 'move-stores'
  },
  TOOLS: {
    id: 'TOOLS',
    path: '/tools',
    pageId: 'TOOLS',
    main: true,
    contentHeading: 'Tools',
    pathId: 'tools'
  },
  MERGE_ENTERPRISE: {
    id: 'MERGE_ENTERPRISE',
    path: '/merge-enterprise',
    pageId: 'MERGE_ENTERPRISE',
    main: true,
    contentHeading: 'Merge Enterprise',
    pathId: 'merge-enterprise'
  },
  BULK_UNASSIGN_USERS: {
    id: 'BULK_UNASSIGN_USERS',
    path: '/enterprise/:enterpriseId/bulk-unassign-users',
    pageId: 'BULK_UNASSIGN_USERS',
    main: true,
    contentHeading: 'Bulk Unassign Users',
    pathId: 'bulk-unassign-users'
  },
  NEW_BULK_UNASSIGN_USERS_JOB: {
    id: 'NEW_BULK_UNASSIGN_USERS_JOB',
    path: '/enterprise/:enterpriseId/new-bulk-unassign-users-job',
    pageId: 'NEW_BULK_UNASSIGN_USERS_JOB',
    contentHeading: 'New Bulk Unassignment',
    pathId: 'new-bulk-unassign-users-job'
  },
  BULK_UNASSIGN_USER_JOB_DETAILS: {
    id: 'BULK_UNASSIGN_USER_JOB_DETAILS',
    path: '/enterprise/:enterpriseId/bulk-unassign-user-job-details/:jobId',
    pageId: 'BULK_UNASSIGN_USER_JOB_DETAILS',
    contentHeading: 'Bulk Unassignment',
    pathId: 'bulk-unassign-user-job-details/:jobId'
  },
  LOOKER_REPORT: {
    id: 'LAST_LOGIN_REPORT',
    path: '/enterprise/:enterpriseId/user-management-report/last-login-report',
    pageId: 'LAST_LOGIN_REPORT',
    main: true,
    contentHeading: 'Last Login Report',
    pathId: 'last_login_report'
  },
  LOOKER_USER_ACTIVITY_REPORT: {
    id: 'USER_ACTIVITY_REPORT',
    path: '/enterprise/:enterpriseId/user-management-report/user-activity-report',
    pageId: 'USER_ACTIVITY_REPORT',
    main: true,
    contentHeading: 'User Activity Report',
    pathId: 'user_activity_report'
  },
  LOOKER_USER_ROLES_REPORT: {
    id: 'USER_ROLES_REPORT',
    path: '/enterprise/:enterpriseId/user-management-report/user-roles-report',
    pageId: 'USER_ROLES_REPORT',
    main: true,
    contentHeading: 'User Roles Report',
    pathId: 'user_roles_report'
  },
  LOOKER_USER_STORES_REPORT: {
    id: 'USER_STORES_REPORT',
    path: '/enterprise/:enterpriseId/user-management-report/user-stores-report',
    pageId: 'USER_STORES_REPORT',
    main: true,
    contentHeading: 'User Stores Report',
    pathId: 'user_stores_report'
  },
  LOOKER_ROLE_DETAILS_REPORT: {
    id: 'ROLE_DETAILS_REPORT',
    path: '/enterprise/:enterpriseId/user-management-report/role-details-report',
    pageId: 'ROLE_DETAILS_REPORT',
    main: true,
    contentHeading: 'Role Details Report',
    pathId: 'role_details_report'
  },
  LOOKER_USER_LIST_REPORT: {
    id: 'USER_LIST_REPORT',
    path: '/enterprise/:enterpriseId/user-management-report/user-list-report',
    pageId: 'USER_LIST_REPORT',
    main: true,
    contentHeading: 'User List Report',
    pathId: 'user_list_report'
  },
  LOOKER_STORE_DEPARTMENT_DETAILS_REPORT: {
    id: 'STORE_DEPARTMENT_DETAILS_REPORT',
    path: '/enterprise/:enterpriseId/user-management-report/store-department-details-report',
    pageId: 'STORE_DEPARTMENT_DETAILS',
    main: true,
    contentHeading: 'Store Department Details Report',
    pathId: 'stor_department_details_report'
  },
  LOOKER_ENTERPRISE_ADMIN_REPORT: {
    id: 'ENTERPRISE_ADMIN_REPORT',
    path: '/enterprise/:enterpriseId/user-management-report/enterprise-admin-report',
    pageId: 'ENTERPRISE_ADMIN_REPORT',
    main: true,
    contentHeading: 'Enterprise Admin Report',
    pathId: 'enterprise_admin_report'
  },
  LOOKER_PRODUCT_CONSUMPTION_REPORT: {
    id: 'PRODUCT_CONSUMPTION_REPORT',
    path: '/enterprise/:enterpriseId/user-management-report/product-consumption-report',
    pageId: 'PRODUCT_CONSUMPTION_REPORT',
    main: true,
    contentHeading: 'Product Consumption Report',
    pathId: 'product_consumption_report'
  }
};

export const pathToPageId = ((): Record<string, string> => {
  const paths: Record<string, string> = {};
  const routeKeys = Object.keys(routeIdentifiers);
  routeKeys.forEach(identifier => {
    const currentObject = routeIdentifiers[identifier] as {
      id: string;
      path: string;
      pageId: string;
    };
    paths[currentObject.path] = currentObject.pageId;
  });
  return paths;
})();

export const pageIdToPath = ((): Record<string, string> => {
  const pages: Record<string, string> = {};
  const routeKeys = Object.keys(routeIdentifiers);
  routeKeys.forEach(identifier => {
    const currentObject = routeIdentifiers[identifier] as {
      id: string;
      path: string;
      pageId: string;
      main?: boolean;
    };
    if (currentObject.main) {
      pages[currentObject.pageId] = currentObject.path;
    }
  });
  return pages;
})();

export const pathToContentHeading = ((): Record<string, string> => {
  const paths: Record<string, string> = {};
  const routeKeys = Object.keys(routeIdentifiers);
  routeKeys.forEach(identifier => {
    const currentObject = routeIdentifiers[identifier] as {
      id: string;
      path: string;
      pageId: string;
      main?: boolean;
      contentHeading: string;
      pathId: string;
    };
    paths[currentObject.pathId] = currentObject.contentHeading;
  });
  return paths;
})();

export const isCDKUserPath = (
  pathName: string,
  enterpriseId: string
): boolean => {
  const isDealerUserView = enterpriseId
    ? pathName.includes(`enterprise/${enterpriseId}`)
    : false;
  return !isDealerUserView;
};

export default routeIdentifiers;
