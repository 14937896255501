/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { GridApi, RowNode } from 'ag-grid-community';
import ErrorToast from 'components/ui/ErrorToast';
import useColumns from 'fixtures/grid/newUserImportStepTwo/columnDefinitions';
import useFilters, {
  UID
} from 'fixtures/grid/newUserImportStepTwo/filterSchema';
import {
  generateFilterSelectionObject,
  getEmptyTableMessage
} from 'helpers/tableHelper';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCNumbersQuery } from 'redux/services/eis-external-attributes-service';
import { useGetDMSUsersQuery } from 'redux/services/identity-service';
import { DmsUserType } from 'redux/services/identity-service/types';
import {
  setContentHeader,
  setSelectedRows
} from 'redux/slices/userImportSlice';
import { RootState } from 'redux/store';
import { handleDownloadClick } from 'utils/utility';
import { useClient } from 'providers/ClientProvider';
import FilterHeader from '../../../templates/TableLayout/FilterHeader/FilterHeader';
import TableContainer from '../../../templates/TableLayout/TableContainer/TableContainer';
import TableContent from '../../../templates/TableLayout/TableContent/TableContent';
import TablePaging from '../../../templates/TableLayout/TablePaging/TablePaging';
import StyledStepTwo from './styled';

import { messages } from '../StepThree/messages';
import {
  getDMSAccounts,
  getDMSProfiles,
  getDMSStores,
  getUpdatedCNumbers,
  getUpdatedOptions
} from './utils';

const StepTwo = (): JSX.Element => {
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const dispatch = useDispatch();
  const intl = useIntl();
  const filters = useFilters();
  const columns = useColumns();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [filterSchema, setFilterSchema] = useState(filters);
  const [filterSelections, setFilterSelections] = useState<
    Record<string, Array<string | undefined>>
  >(generateFilterSelectionObject(filterSchema));
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [updatedDmsData, setUpdatedDmsData] = useState<Record<string, any>>();

  const paginationHeight = 103;
  const headerSectionHeight = 34;
  const displayedRowCount =
    gridApi && gridApi.getDisplayedRowCount
      ? gridApi.getDisplayedRowCount()
      : 0;
  const { selectedCNumber, selectedRowsStepTwo } = useSelector(
    (state: RootState) => state.slices.userImportState
  );

  const client = useClient();
  const { userInfo, enterpriseId } = client;

  const { data: cNumbers = [] } = useGetCNumbersQuery(enterpriseId, {
    skip: !userInfo?.fullUser
  });

  useEffect(() => {
    const navTitle = intl.formatMessage(messages.userImportTemplate);
    dispatch(setContentHeader(navTitle));
  }, [dispatch, intl]);

  const {
    data: dmsData,
    isFetching,
    isLoading,
    isError,
    error
  } = useGetDMSUsersQuery(
    {
      enterpriseId,
      cnumber:
        selectedCNumber?.value === 'All' ? '' : selectedCNumber?.value || ''
    },
    { skip: !selectedCNumber }
  );

  useEffect(() => {
    if (dmsData?.dmsUsers) {
      const dmsProfiles = getDMSProfiles(dmsData?.dmsUsers);
      const dmsAccounts = getDMSAccounts(dmsData?.dmsUsers);
      const dmsStores = getDMSStores(dmsData?.dmsUsers);

      const updatedDMSStoresOptions = getUpdatedOptions(dmsStores);
      const updatedDMSJobProfileOptions = getUpdatedOptions(dmsProfiles);
      const updatedDMSAccountOptions = getUpdatedOptions(dmsAccounts);

      const updatedFilterSchema = filterSchema.map(filter => {
        if (filter.uid === UID.JOB_PROFILES) {
          filter.options = updatedDMSJobProfileOptions;
          return filter;
        }
        if (filter.uid === UID.PROFILES) {
          filter.options = updatedDMSAccountOptions;
          return filter;
        }
        if (filter.uid === UID.STORES) {
          filter.options = updatedDMSStoresOptions;
          return filter;
        }
        return filter;
      });
      setFilterSchema(updatedFilterSchema);
    }

    const modifiedDmsUsers = dmsData?.dmsUsers?.map(
      (dmsUserDetails: DmsUserType) => {
        if (selectedCNumber?.value === 'All') {
          const matchingCNum = cNumbers.find(
            cNum =>
              cNum.value?.toLowerCase() ===
              dmsUserDetails.cNumber?.toLowerCase()
          );
          return matchingCNum
            ? {
                ...dmsUserDetails,
                cNumberAlias: matchingCNum?.alias,
                stores: []
              }
            : { ...dmsUserDetails, cNumberAlias: '', stores: [] };
        }
        return dmsUserDetails.cNumber?.toLowerCase() ===
          selectedCNumber?.value?.toLowerCase()
          ? {
              ...dmsUserDetails,
              cNumberAlias: selectedCNumber?.alias,
              stores: []
            }
          : { ...dmsUserDetails, cNumberAlias: '', stores: [] };
      }
    );
    setUpdatedDmsData({ ...dmsData, dmsUsers: modifiedDmsUsers });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dmsData?.dmsUsers]);

  useEffect(() => {
    if (cNumbers?.length && selectedCNumber?.value === 'All') {
      const updatedOptions = getUpdatedCNumbers(cNumbers);
      const updatedFilterSchema = filterSchema.map(filter => {
        if (filter.uid === UID.CNUMBER) {
          return {
            ...filter,
            options: updatedOptions
          };
        }
        return filter;
      });
      setFilterSchema(updatedFilterSchema);
    }
    if (cNumbers?.length && selectedCNumber?.value !== 'All') {
      const updatedFilterSchema = filterSchema.filter(
        filter => filter.uid !== UID.CNUMBER
      );
      setFilterSchema(updatedFilterSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cNumbers, selectedCNumber]);

  const onFirstDataRendered = (params: {
    api: { getRenderedNodes: () => RowNode[] };
  }) => {
    if (selectedRowsStepTwo) {
      params.api.getRenderedNodes().forEach((node: RowNode) => {
        if (selectedRowsStepTwo.some(row => row.id === node.data.id)) {
          node.setSelected(true);
        }
      });
    }
  };

  const onSelectionChanged = (event: any) => {
    const selectedData = event.api.getSelectedRows() || [];
    const selectedDataIds = new Set(
      selectedData.map((row: { id: any }) => row.id)
    );
    const selectedRowsStepTwoIds = new Set(
      selectedRowsStepTwo?.map(row => row.id) || []
    );

    const filteredSelectedRows =
      selectedRowsStepTwo?.filter(row => selectedDataIds.has(row.id)) || [];
    const rowsToAdd = selectedData.filter(
      (row: { id: any }) => !selectedRowsStepTwoIds.has(row.id)
    );
    dispatch(setSelectedRows([...filteredSelectedRows, ...rowsToAdd]));
  };

  const TABLE_HEIGHT =
    containerRef && containerRef.current && containerRef.current.clientHeight
      ? containerRef.current.clientHeight -
        paginationHeight -
        headerSectionHeight
      : 300;

  return (
    <>
      {isError && <ErrorToast dataError={error} />}

      {userInfo?.fullUser && (
        <StyledStepTwo ref={containerRef}>
          <TableContainer showBorder>
            <FilterHeader
              filterSchema={filterSchema}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              filterSelections={filterSelections}
              setFilterSelections={setFilterSelections}
              isDownloadable="true"
              onDownloadClick={(): void => handleDownloadClick(gridApi)}
              disableDownloadButton={displayedRowCount === 0}
            />
            <TableContent
              gridApi={gridApi}
              searchValue={searchValue}
              filterSchema={filterSchema}
              tableHeight={TABLE_HEIGHT}
              filterSelections={filterSelections}
              setGridApi={setGridApi}
              setCurrentPage={setCurrentPage}
              setTotalPages={setTotalPages}
              setTotalRows={setTotalRows}
              columns={columns}
              loading={isFetching || isLoading}
              data={updatedDmsData?.dmsUsers}
              onSelectionChanged={onSelectionChanged}
              overlayNoRowsTemplateMessage={getEmptyTableMessage(
                searchValue,
                intl
              )}
              rowHeight={60}
              onFirstDataRendered={onFirstDataRendered}
            />
            <TablePaging
              gridApi={gridApi}
              currentPage={currentPage}
              totalPages={totalPages}
              totalRows={totalRows}
            />
          </TableContainer>
        </StyledStepTwo>
      )}
    </>
  );
};

export default StepTwo;
