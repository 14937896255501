import styled from 'styled-components';
import { Link } from 'cdk-radial';

export const StyledLink = styled(Link)`
  color: '#006cfa';
  text-decoration: none;
  cursor: pointer;
`;

const StyledStores = styled.div`
  margin-bottom: 1rem;
  .para-custom {
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export default StyledStores;
