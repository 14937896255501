import styled from 'styled-components';
import { devices } from 'constants/devices';
import { IconValet, ShellContainer, Button } from 'cdk-radial';
import { Z_INDEX } from 'constants/zindex';

export const StyledSideNav = styled(ShellContainer)`
  display: contents;
  height: calc(100vh - 56px);
  margin-left: -7px;
  grid-template-rows: auto auto 1fr auto;

  .avatar-container-column {
    max-width: 20%;
  }

  .desc-container-column {
    max-width: 80%;
  }

  .rounded-row {
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    background-color: transparent;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .nav-container {
    padding: 9px;
    height: 98vh;
  }
  .rounded-row-box {
    padding: 0.3rem;
  }

  .collapse-enterprise-container {
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    background-color: transparent;
  }

  .element-state-wrapper-on-click-cdk-home-row {
    margin-bottom: 8px;
  }

  .grid-container {
    margin-top: -6px;
  }
`;

export const StyledIconInfo = styled(IconValet)`
  padding: 0px 12px 0px 14px;
  fill: darkgrey;
`;

export const StyledDiv = styled.div`
  position: absolute;

  min-width: 12%;
  width: 14%;
  z-index: ${Z_INDEX.MAX_INDEX};
  @media ${devices.laptop} {
    top: 77%;
    left: 3.5%;
  }

  @media ${devices.desktop} {
    top: 82%;
    left: 3%;
  }
`;

export default StyledSideNav;

type StyledSideNavItemsContainerProps = {
  isCollapsed: boolean;
};

export const StyledSideNavItemsContainer = styled.div<StyledSideNavItemsContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  justify-content: flex-start;
  flex-direction: ${({ isCollapsed }) => (isCollapsed ? 'column' : 'row')};
`;

export const StyledSignoutDialog = styled.div`
  position: fixed;
  bottom: 100px;
  left: 55px;
  z-index: ${Z_INDEX.MAX_INDEX};
  width: 200px;
  background-color: unset;
`;

export const StylednewCommonAdminHeader = styled.div`
  margin-left: 7% !important;
`;

export const StyledAppSwitch = styled.div`
  z-index: ${Z_INDEX.MAX_INDEX};
  background-color: transparent;
  margin-bottom: 8px;
`;

export const StyledImpersonateContainer = styled.div`
.impersonations-loader {
    margin-left: 30px !important;
 }

  .accountPopup {
    position: absolute;
    right: 2px;
    top: 44px; //Top Header Height
    z-index: ${Z_INDEX.ACCOUNT_POPUP_INDEX};
    width: 16rem;
  }

  .toggle-switch {
    span {
      color: #fff;
    }
  }

  .switch-label {
    margin-bottom: 0.11rem;
  }

  .impersonation-container {
    margin: 0px 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .impersonation-label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .show-counter {
    .counter-item {
      padding: 0 0.25rem;
  }

  .impersonation-status {
      height: 32px;
      width: 100%;
  }
`;

export const StyledModernAppSwitcherContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

export const StyledButtonImperpesonateContainer = styled(Button)`
  size: large;
  color: white;
  margin-bottom: 8px;
  width: 100%;
`;

export const StyledSwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSwitchToggleContainer = styled.div`
  font-size: smaller;
  margin-left: -8px;
`;

export const StyledAppSwitchWrapper = styled.div`
  z-index: ${Z_INDEX.MAX_INDEX};
  background-color: transparent;
`;

export const StyledAppNavigationMenuContainer = styled.div`
  height: 390px;
  overflow-y: auto;
`;
