export const Z_INDEX = {
  LOOKER_REPORT_INDEX: '0',
  TAB_CONTAINER_INDEX: '1',
  TABLE_PAGINATION_INDEX: '51',
  ACCOUNT_POPUP_INDEX: '55',
  TAB_INDEX: '100',
  ROLES_DROPDOWN: '110',
  MENU_INDEX: '160',
  ERROR_TOAST_INDEX: '200',
  MAX_INDEX: '9999'
};
