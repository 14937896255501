/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useOktaAuth } from '@okta/okta-react';
import { TOAST_VARIANTS } from 'cdk-radial';
import ErrorToast from 'components/ui/ErrorToast';
import { AMP_EVENT_TYPES } from 'constants/index';
import { useAnalytics } from 'dms-analytics';
import { ACTIVE, INACTIVE } from 'fixtures/headerConstant';
import { includes } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  useGetEnterpriseCDKAccessDetailsQuery,
  useGetUserImpersonationStatusQuery,
  usePostInvokeImpersonationMutation,
  useRevokeImpersonationMutation
} from 'redux/services/authz-service';
import { useGetCdkEditAccessEnterpriseListQuery } from 'redux/services/identity-service';
import { setGlobalToast, setImpersonation } from 'redux/slices/commonSlice';
import { useClient } from 'providers/ClientProvider';
import NoPermissionDialog from '../../../../molecules/common/Header/NoPermissionDialog/NoPermissionDialog';
import RevokeImpersonationDialog from '../../../../molecules/common/Header/RevokeImpersonationDialog/RevokeImpersonationDialog';
import { messages } from '../messages';
import StyledHeader from '../styled';
import ImpersonationContainer from './ImpersonationContainer';
import { ImpersonatationDetailsProps } from '../type';
import {
  hasCDKAccessPermission,
  hasImpersonationPermission,
  hasLoggedInAsCDKUser
} from '../utils';

const ImpersonatationDetails = ({
  setImpersonateRefresh
}: ImpersonatationDetailsProps): JSX.Element => {
  const intl = useIntl();
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();
  const { trackEvent, identify } = useAnalytics();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isNoPermissionDialogOpen, setNoPermissionDialogOpen] = useState(false);

  const client = useClient();
  const { userInfo, enterpriseId, permissions, enterpriseBasicDetails } =
    client;

  const userEnterpriseName = enterpriseBasicDetails?.name;

  const permissionsAssignedToUser = permissions?.allPermissionsAssigedToUser;
  const canImpersonate = hasImpersonationPermission(
    userInfo,
    permissionsAssignedToUser,
    enterpriseId
  );
  const userGuid = userInfo?.fullUser?.user?.userGuid || '';

  const {
    data: userImpersonationDetais,
    isLoading: isUserImpersonationStatusLoading,
    error: isUserImpersonationDetailsError
  } = useGetUserImpersonationStatusQuery(userGuid, {
    skip: !enterpriseId || !canImpersonate
  });

  const [
    postInvokeImpersonation,
    {
      isLoading: postInvokeImpersonationLoading,
      isError: isPostInvokeImpersonationError,
      error: postImpersonationError
    }
  ] = usePostInvokeImpersonationMutation();

  const [
    revokeImpersonation,
    {
      isLoading: revokeImpersonationLoading,
      isError: isRevokeImpersonationError,
      error: impersonationError
    }
  ] = useRevokeImpersonationMutation();

  const {
    data: cdkEditAccessEnterpriseList,
    isSuccess: isCdkEditAccessEnterpriseListSuccess
  } = useGetCdkEditAccessEnterpriseListQuery(undefined, {
    skip: !enterpriseId
  });

  const {
    data: enterpriseCDKAccessDetails,
    isError: isEnterpriseCDKAccessDetailsError,
    error: enterpriseCDKAccessDetailsError
  } = useGetEnterpriseCDKAccessDetailsQuery(enterpriseId || '', {
    skip:
      !isCdkEditAccessEnterpriseListSuccess ||
      !includes(cdkEditAccessEnterpriseList?.enterprises, enterpriseId)
  });

  useEffect(() => {
    if (
      userImpersonationDetais &&
      userImpersonationDetais?.impersonationStatus === ACTIVE &&
      userImpersonationDetais?.enterpriseId === enterpriseId
    ) {
      dispatch(setImpersonation(true));
    } else {
      dispatch(setImpersonation(false));
    }
  }, [userImpersonationDetais, dispatch, enterpriseId]);

  const modernCAMAmplitude = localStorage.getItem('modernCAMAmplitude');

  const handleTrackEvent = useCallback(
    (event: string, properties: Record<string, unknown>) => {
      if (!hasLoggedInAsCDKUser(authState)) {
        trackEvent(event, properties);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackEvent]
  );

  useEffect(() => {
    if (trackEvent && modernCAMAmplitude !== 'true') {
      handleTrackEvent(AMP_EVENT_TYPES.SELECT_INTERFACE, {
        'Legacy Or Modern': 'Modern'
      });
      localStorage.setItem('modernCAMAmplitude', 'true');
    }
  }, [handleTrackEvent, modernCAMAmplitude, trackEvent]);

  useEffect(() => {
    if (enterpriseId && userEnterpriseName) {
      identify({
        'Enterprise ID': enterpriseId,
        'Enterprise Name': userEnterpriseName
      });
    }
  }, [identify, enterpriseId, userEnterpriseName]);

  const impersonationDetails = {
    impersonationTimeLeft: userImpersonationDetais?.expiryTimeOfSession || '',
    impersonationStatus: userImpersonationDetais?.impersonationStatus || '',
    impersonatingEnterpriseId: userImpersonationDetais?.enterpriseId || '',
    currentEnterpriseId: enterpriseId,
    hasImpersonationPermission: canImpersonate,
    isLoading:
      isUserImpersonationStatusLoading ||
      postInvokeImpersonationLoading ||
      revokeImpersonationLoading
  };

  const handleRevokeConfirmation = async () => {
    setIsConfirmDialogOpen(false);
    const resp = await revokeImpersonation(userGuid);
    if (!('error' in resp)) {
      setImpersonation(false);
      dispatch(
        setGlobalToast({
          content: intl.formatMessage(messages.disableImpersonation, {
            enterprise: userImpersonationDetais?.enterpriseId || ''
          }),
          variant: TOAST_VARIANTS.POSITIVE
        })
      );
      setImpersonateRefresh(false);
    }
  };

  const handleImpersonationChange = async (val: Array<string>) => {
    const initiateImpersonation =
      (val[0] === INACTIVE &&
        hasCDKAccessPermission(enterpriseCDKAccessDetails)) ||
      (isUserImpersonationDetailsError &&
        'status' in isUserImpersonationDetailsError &&
        isUserImpersonationDetailsError.status === 404);

    if (initiateImpersonation) {
      const payload = {
        userGuid,
        body: { enterpriseId }
      };
      const resp = await postInvokeImpersonation(payload);
      if (!('error' in resp)) {
        setImpersonateRefresh(true);
        setImpersonation(true);
        dispatch(
          setGlobalToast({
            content: intl.formatMessage(messages.enableImpersonation, {
              enterprise: enterpriseId
            }),
            variant: TOAST_VARIANTS.POSITIVE
          })
        );
      }
    } else if (
      val[0] === INACTIVE &&
      !hasCDKAccessPermission(enterpriseCDKAccessDetails)
    ) {
      setNoPermissionDialogOpen(true);
    } else {
      setIsConfirmDialogOpen(true);
    }
  };

  return (
    <StyledHeader>
      {isEnterpriseCDKAccessDetailsError && (
        <ErrorToast dataError={enterpriseCDKAccessDetailsError} />
      )}

      {isPostInvokeImpersonationError && (
        <ErrorToast dataError={postImpersonationError} />
      )}

      {isRevokeImpersonationError && (
        <ErrorToast dataError={impersonationError} />
      )}

      <>
        <div data-testid="Top-header" className="top-header">
          <ImpersonationContainer
            impersonationDetails={impersonationDetails}
            handleImpersonationChange={val => handleImpersonationChange(val)}
          />
          <RevokeImpersonationDialog
            setIsConfirmDialogOpen={setIsConfirmDialogOpen}
            isConfirmDialogOpen={isConfirmDialogOpen}
            handleSave={handleRevokeConfirmation}
            enterpriseId={userImpersonationDetais?.enterpriseId || ''}
          />
          <NoPermissionDialog
            setIsConfirmDialogOpen={setNoPermissionDialogOpen}
            isConfirmDialogOpen={isNoPermissionDialogOpen}
          />
        </div>
      </>
    </StyledHeader>
  );
};
export default ImpersonatationDetails;
