/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { createApi } from '@reduxjs/toolkit/query/react';
import { EnterpriseAdmin } from '../types/eis-core-service-types';
import apiEndPoints from './apiEndPoints';
import baseQuery from './baseQuery';
import {
  AssignRole,
  AssignStore,
  CreateRoleType,
  DefaultStore,
  GetAdminsByStoreId,
  GetEnterprisePermissionType,
  GetRolePermissionsType,
  PostUserByRolesPayload,
  PostUserRolesPayload,
  PostUserStoresPayload,
  RoleTemplatesResponse,
  RolesResponse,
  UserRole,
  UserStore
} from './types';

export const adminServiceAPI = createApi({
  reducerPath: 'admin',
  baseQuery,
  tagTypes: ['getEnterpriseDetails'],
  endpoints: builder => ({
    getStoreEnterpriseAdmin: builder.query<EnterpriseAdmin, string>({
      query: (storeId: string) =>
        `${apiEndPoints.getStoreAdminDetails(storeId)}`
    }),
    getUserRoles: builder.query<UserRole[], string>({
      query: (user: string) => `${apiEndPoints.getUserRoles(user)}`
    }),
    getUserStores: builder.query<UserStore[], string>({
      query: (user: string) => `${apiEndPoints.getUserStores(user)}`
    }),

    deleteAssignedRoleUser: builder.mutation<void, PostUserByRolesPayload>({
      query: (body: any) => ({
        url: `${apiEndPoints.postUserassignmentByRole}?action=delete`,
        method: 'POST',
        body
      })
    }),
    createAssignedRoleUser: builder.mutation<void, PostUserByRolesPayload>({
      query: (body: any) => ({
        url: `${apiEndPoints.postUserassignmentByRole}?action=create`,
        method: 'POST',
        body
      })
    }),

    postBulkAssignRoles: builder.mutation<void, PostUserRolesPayload>({
      query: body => ({
        url: `${apiEndPoints.postBulkAssignRoles}`,
        method: 'POST',
        body
      })
    }),
    postBulkAssignStores: builder.mutation<void, PostUserStoresPayload>({
      query: body => ({
        url: `${apiEndPoints.postBulkAssignStores}`,
        method: 'POST',
        body
      })
    }),
    postBulkUnAssignRoles: builder.mutation<void, PostUserRolesPayload>({
      query: body => ({
        url: `${apiEndPoints.postBulkUnAssignRoles}`,
        method: 'POST',
        body
      })
    }),
    postBulkUnAssignStores: builder.mutation<void, PostUserStoresPayload>({
      query: body => ({
        url: `${apiEndPoints.postBulkUnAssignStores}`,
        method: 'POST',
        body
      })
    }),
    getAllRoles: builder.query<RolesResponse, string>({
      query: (enterpriseId: string) =>
        `${apiEndPoints.getAllRoles(enterpriseId)}`
    }),
    getAllRoleTemplates: builder.query<RoleTemplatesResponse, string>({
      query: (enterpriseId: string) =>
        `${apiEndPoints.getAllRoleTemplates(enterpriseId)}`
    }),
    getEnterprisePermissions: builder.query<
      GetEnterprisePermissionType,
      Record<string, string>
    >({
      query: ({ enterpriseId }) =>
        `${apiEndPoints.getEnterprisePermissions(enterpriseId)}`
    }),
    getRolePermissions: builder.query<
      GetRolePermissionsType,
      Record<string, string>
    >({
      query: ({ enterpriseId, roleGuid }) =>
        `${apiEndPoints.getAllRoles(enterpriseId)}/${roleGuid}/permissions`
    }),

    createRole: builder.mutation<CreateRoleType, any>({
      query: ({ body, enterpriseId }: any) => ({
        url: `${apiEndPoints.getAllRoles(enterpriseId)}`,
        method: 'POST',
        body
      })
    }),
    updateRole: builder.mutation<CreateRoleType, any>({
      query: ({ body, enterpriseId, roleGuid }: any) => ({
        url: `${apiEndPoints.updateRoles(enterpriseId, roleGuid)}`,
        method: 'PUT',
        body
      })
    }),
    getAdminsByStoreId: builder.query<GetAdminsByStoreId, string>({
      query: (storeId: string) =>
        `${apiEndPoints.getStoreAdminDetails(storeId)}`
    }),
    getAdminsByStoreIdWithPermission: builder.query<GetAdminsByStoreId, string>(
      {
        query: (loginId: string) =>
          `${apiEndPoints.getStoreAdminDetailsWithPermissions(loginId)}`
      }
    ),
    postRoleAssign: builder.mutation<void, AssignRole>({
      query: ({ body }) => ({
        url: `${apiEndPoints.postRoleAssign}`,
        method: 'POST',
        body
      })
    }),
    postStoreAssign: builder.mutation<void, AssignStore>({
      query: ({ body }) => ({
        url: `${apiEndPoints.postStoreAssign}`,
        method: 'POST',
        body
      })
    }),
    postRoleUnAssign: builder.mutation<void, AssignRole>({
      query: ({ body }) => ({
        url: `${apiEndPoints.postRoleUnassign}`,
        method: 'POST',
        body
      })
    }),
    postStoreUnAssign: builder.mutation<void, AssignStore>({
      query: ({ body }) => ({
        url: `${apiEndPoints.postStoreUnassign}`,
        method: 'POST',
        body
      })
    }),
    putDefaultStore: builder.mutation<string, DefaultStore>({
      query: body => ({
        url: `${apiEndPoints.putDefaultStore(body.loginId)}`,
        method: 'PUT',
        body: body.payload
      })
    }),
    getStoreLevelRoles: builder.query<any, Record<string, string>>({
      query: ({ enterpriseId, userId, simpleId }) =>
        `${apiEndPoints.getStoreLevelRoles(enterpriseId, userId, simpleId)}`
    })
  })
});

export const {
  useGetStoreEnterpriseAdminQuery,
  useGetUserRolesQuery,
  useGetUserStoresQuery,
  usePostBulkAssignRolesMutation,
  usePostBulkAssignStoresMutation,
  usePostBulkUnAssignRolesMutation,
  usePostBulkUnAssignStoresMutation,
  useDeleteAssignedRoleUserMutation,
  useCreateAssignedRoleUserMutation,
  useGetAllRolesQuery,
  useGetAllRoleTemplatesQuery,
  useGetEnterprisePermissionsQuery,
  useGetRolePermissionsQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  usePutDefaultStoreMutation,
  usePostRoleAssignMutation,
  usePostRoleUnAssignMutation,
  usePostStoreAssignMutation,
  usePostStoreUnAssignMutation,
  useGetAdminsByStoreIdWithPermissionQuery,
  useGetStoreLevelRolesQuery
} = adminServiceAPI;
