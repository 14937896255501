import { BUTTON_VARIANTS } from 'cdk-radial';
import { StyledWarningDialog } from './styled';
import { WarningDialogProps } from './types';

export const WarningDialog = ({
  title,
  description,
  primaryButtonText,
  secondaryButtonText,
  handleOk,
  handleClose,
  hideCloseButton
}: WarningDialogProps): JSX.Element => {
  const BUTTON_PROPS = [
    {
      id: 'dialog-2-action-1',
      onClick: handleClose,
      text: secondaryButtonText,
      variant: 'text'
    },
    {
      id: 'dialog-2-action-2',
      onClick: handleOk,
      text: primaryButtonText,
      variant: BUTTON_VARIANTS.PRIMARY
    }
  ];

  return (
    <StyledWarningDialog
      dataTestId="warning-dialog"
      disableOverlayClicked={false}
      id="warning-dialog"
      isOpen
      onClose={handleClose}
      title={title}
      buttonsProps={BUTTON_PROPS}
      hideCloseButton={hideCloseButton}
    >
      {description}
    </StyledWarningDialog>
  );
};
